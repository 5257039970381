import React from "react";
import queryString from "query-string";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Select from "react-select";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import PostsList from "../components/posts/list";
import { mergePosts } from "../util/posts";
// import { Search } from "../util/icons";

export const query = graphql`
  query ResourceQuery {
    page: allSanityPageResources {
      nodes {
        title
        subtitle
      }
    }
    articles: allSanityArticle {
      nodes {
        ...articleTileFields
      }
    }
    resource: allSanityResource {
      nodes {
        ...resourceTileFields
      }
    }

    categories: allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityTag(sort: { fields: title, order: ASC }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`;

class Research extends React.Component {
  constructor(props) {
    super(props);

    this.posts = mergePosts(props.data);
    this.page = props.data.page.nodes[0];

    this.types = [
      { value: "article", label: "Articles" },
      { value: "event", label: "Events" },
      { value: "resource", label: "Resources" },
    ];

    this.categories = props.data.categories.nodes.map(item => {
      return {
        value: item.slug.current,
        label: item.title,
      };
    });

    this.tags = props.data.tags.nodes
      .filter(item => !item.title.toLowerCase().includes("event"))
      .map(item => {
        return {
          value: item.slug.current,
          label: item.title,
        };
      });

    this.state = {
      filteredPosts: this.posts,
      open: false,
      searchText: "",
      ...this.getInitialValues(),
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleFiltersToggle = this.handleFiltersToggle.bind(this);
    this.filterPosts = this.filterPosts.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    this.filterPosts();
  }

  componentDidUpdate(prevProps) {
    // See if URL params have changed.
    // If true it indicated that a user has clicked a tag on a tile on this page
    // and we need to read values from window.location
    if (
      typeof window !== "undefined" &&
      prevProps.location.search !== window.location.search
    ) {
      const urlData = this.getInitialValues();

      this.setState(
        {
          tag: urlData?.tag,
          type: urlData?.type,
          category: urlData?.category,
        },
        this.filterPosts
      );
    }
  }

  getInitialValues() {
    const initial = {
      type: null,
      category: null,
      tag: null,
    };

    const { type, category, tag } =
      typeof window !== "undefined" &&
      queryString.parse(window.location.search);

    if (type) {
      const selected = this.types.find(item => item.value === type);
      if (selected) {
        initial.type = selected;
      }
    }

    if (category) {
      const selected = this.categories.find(item => item.value === category);
      if (selected) {
        initial.category = selected;
      }
    }

    if (tag) {
      const selected = this.tags.find(item => item.value === tag);
      if (selected) {
        initial.tag = selected;
      }
    }

    return initial;
  }

  handleFiltersToggle() {
    trackCustomEvent({
      category: "Content Finder",
      action: "Filter toggle",
      label: this.open ? "Close" : "Open",
    });
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  handleTypeChange(event) {
    if (event) {
      trackCustomEvent({
        category: "Content Finder",
        action: "Type",
        label: event.label,
      });
    }

    this.setState({ type: event }, this.filterPosts);
  }

  handleCategoryChange(event) {
    if (event) {
      trackCustomEvent({
        category: "Content Finder",
        action: "Category",
        label: event.label,
      });
    }
    this.setState({ category: event }, this.filterPosts);
  }

  handleTagChange(event) {
    if (event) {
      trackCustomEvent({
        category: "Content Finder",
        action: "Tag",
        label: event.label,
      });
    }
    this.setState({ tag: event }, this.filterPosts);
  }
  handleSearchChange(event) {
    this.setState({
      searchText: event.target.value,
    });
  }
  filterPosts() {
    const posts = this.posts.filter(post => {
      const isType = !this.state.type || post._type === this.state.type.value;
      const isCategory =
        !this.state.category ||
        (post.category &&
          post.category.slug.current === this.state.category.value);
      const isTag =
        !this.state.tag ||
        post.tags.map(tag => tag.slug?.current).includes(this.state.tag.value);

      return isType && isCategory && isTag;
    });
    this.setState({ filteredPosts: posts });
  }

  render() {
    const filtersClass = `filters filters--${
      this.state.open ? "open" : "closed"
    }`;

    const filteredData = this.state.filteredPosts.filter(i => {
      if (this.state.searchText === "") {
        return this.state.filteredPosts;
      } else {
        return i.title
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      }
    });
    // console.log(this.state.filteredData);
    return (
      <Layout>
        <SEO title={this.page.title} description={this.page.subtitle} />
        <Hero title={this.page.title} description={this.page.subtitle}></Hero>
        <div className="container">
          <form className={filtersClass}>
            <div className="title" onClick={this.handleFiltersToggle}>
              <span>Filter by:</span>
              <div
                className="filter__indicator filter__dropdown-indicator"
                aria-hidden="true"
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </div>
            </div>
            {/* <Select
              value={this.state.type}
              name="type"
              placeholder="Type"
              isClearable
              options={this.types}
              onChange={this.handleTypeChange}
              className="filter"
              classNamePrefix="filter"
            /> */}
            {/* <Select
              value={this.state.category}
              name="category"
              placeholder="Category"
              isClearable
              options={this.categories}
              onChange={this.handleCategoryChange}
              className="filter"
              classNamePrefix="filter"
            /> */}

            <div className="filter" classNamePrefix="filter">
              <input
                value={this.state.searchText}
                onChange={this.handleSearchChange}
                placeholder="Search content ..."
              />
            </div>
            <div className="filter" classNamePrefix="filter">
              <Select
                value={this.state.tag}
                name="tag"
                placeholder="Tag"
                isClearable
                options={this.tags}
                onChange={this.handleTagChange}
                className="filter tag"
                classNamePrefix="filter"
              />
            </div>
          </form>
          {this.state.filteredPosts.length && filteredData.length !== 0 ? (
            <PostsList posts={filteredData} condensed={true} />
          ) : (
            <div className="no-results">
              No results for your current filters.
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export default Research;
